import { template as template_217c3d93e15b484bbd16c1b6bac759a9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_217c3d93e15b484bbd16c1b6bac759a9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
